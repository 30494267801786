import React from 'react';
import moment from 'moment';
import { ReportTable } from './ReportTable';
import { getAuthentications } from '../../utils/services';

const AuthenticationReport = () => {
  const columns = React.useMemo(
    () => [
      { Header: 'Authentication activity'
      , columns: 
        [ { Header: 'Date'
          , disableFilters: true
          , accessor: d => {
              return moment.utc(d.createdDtm).local().format('lll (ddd)')
            }
          }
        , { Header: 'Mobile'
          , disableFilters: true
          , accessor: d => {
              const cleaned = ('' + d.phoneNumber.slice(-10)).replace(/\D/g, '');
              const match = /^(\d{3})(\d{3})(\d{4})$/.exec(cleaned);
              if (match) {
                return '(' + match[1] + ') ' + match[2] + '-' + match[3];
              }
              return d.phoneNumber;
            }
          }
        , { Header: 'Agent'
          , accessor: 'agentName'
          , disableFilters: true
          }
        , { Header: 'Type'
          , accessor: 'verificationType'
          , disableFilters: true
          }
        , { Header: 'Result'
          , accessor: 'status'
          , disableFilters: true
          }
        , { id: 'full_name'
          , Header: 'Full Name'
          , disableFilters: true
          , accessor: d => {  // capitalize
            if(d.fullName){
              return d.fullName.replace(/\w\S*/g, function(txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
              })
            } else {
              return '';
            }
            }
          }
        , { Header: 'IP Address'
          , accessor: 'ipAddress'
          , disableFilters: true
          }
        ]
      }
    ],
    []
  )
  
  return (
    <>
      <div className='row'>
        <div className='col-lg-12'>
          <div className='round-border card'>
            <ReportTable 
              header='Authentications' 
              columns={columns} 
              reportName='authentications' 
              refreshDataFunc={getAuthentications}
            />
            <div className='card-footer border-top-0'></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AuthenticationReport;